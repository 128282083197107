.ce-submarine-delimiter {
  line-height: 1.6em;
  width: 100%;
  text-align: center;
}

.ce-submarine-delimiter:before {
  display: inline-block;
  line-height: 65px;
  height: 30px;
  letter-spacing: 0.2em;
}

.ce-submarine-delimiter svg {
  fill: white;
}

.ce-submarine-delimiter-icon {
  color: white;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip svg {
  width: 24px;
  height: 24px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  bottom: 100%;
  right: 50%;
  transform: translateX(50%);
}

.tooltip .tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.tooltip .tooltiptext {
  opacity: 0;
  transition: opacity 1s;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
}
