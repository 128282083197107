/* Cover Maker Fonts */
@font-face {
    font-family: 'LEMON MILK';
    src: url('../../fonts/coverMaker/LEMONMILK-Regular.woff2') format('woff2'),
      url('../../fonts/coverMaker/LEMONMILK-Regular.woff') format('woff'),
      url('../../fonts/coverMaker/LEMONMILK-Regular.ttf') format('truetype'),
      url('../../fonts/coverMaker/LEMONMILK-Regular.svg#LEMONMILK-Regular')
        format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Creepster';
    src: url('../../fonts/coverMaker/Creepster-Regular.woff2') format('woff2'),
      url('../../fonts/coverMaker/Creepster-Regular.woff') format('woff'),
      url('../../fonts/coverMaker/Creepster-Regular.ttf') format('truetype'),
      url('../../fonts/coverMaker/Creepster-Regular.svg#Creepster-Regular')
        format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Cocogoose';
    src: url('../../fonts/coverMaker/Cocogoose.woff2') format('woff2'),
      url('../../fonts/coverMaker/Cocogoose.woff') format('woff'),
      url('../../fonts/coverMaker/Cocogoose.ttf') format('truetype'),
      url('../../fonts/coverMaker/Cocogoose.svg#Cocogoose') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Gloria Hallelujah';
    src: url('../../fonts/coverMaker/GloriaHallelujah.woff2') format('woff2'),
      url('../../fonts/coverMaker/GloriaHallelujah.woff') format('woff'),
      url('../../fonts/coverMaker/GloriaHallelujah.ttf') format('truetype'),
      url('../../fonts/coverMaker/GloriaHallelujah.svg#GloriaHallelujah') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'DS-Digital';
    src: url('../../fonts/coverMaker/DS-Digital-Bold.woff2') format('woff2'),
      url('../../fonts/coverMaker/DS-Digital-Bold.woff') format('woff'),
      url('../../fonts/coverMaker/DS-Digital-Bold.ttf') format('truetype'),
      url('../../fonts/coverMaker/DS-Digital-Bold.svg#DS-Digital-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Akira Expanded';
    src: url('../../fonts/coverMaker/AkiraExpanded-SuperBold.woff2') format('woff2'),
      url('../../fonts/coverMaker/AkiraExpanded-SuperBold.woff') format('woff'),
      url('../../fonts/coverMaker/AkiraExpanded-SuperBold.ttf') format('truetype'),
      url('../../fonts/coverMaker/AkiraExpanded-SuperBold.svg#AkiraExpanded-SuperBold')
        format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Syne Mono';
    src: url('../../fonts/coverMaker/SyneMono-Regular.woff2') format('woff2'),
      url('../../fonts/coverMaker/SyneMono-Regular.woff') format('woff'),
      url('../../fonts/coverMaker/SyneMono-Regular.ttf') format('truetype'),
      url('../../fonts/coverMaker/SyneMono-Regular.svg#SyneMono-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Limelight';
    src: url('../../fonts/coverMaker/Limelight-Regular.woff2') format('woff2'),
      url('../../fonts/coverMaker/Limelight-Regular.woff') format('woff'),
      url('../../fonts/coverMaker/Limelight-Regular.ttf') format('truetype'),
      url('../../fonts/coverMaker/Limelight-Regular.svg#Limelight-Regular')
        format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Storm Gust';
    src: url('../../fonts/coverMaker/StormGust.woff2') format('woff2'),
      url('../../fonts/coverMaker/StormGust.woff') format('woff'),
      url('../../fonts/coverMaker/StormGust.ttf') format('truetype'),
      url('../../fonts/coverMaker/StormGust.svg#StormGust') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  