.popup-options {
  box-shadow: 0px 9px 50px -12px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  max-height: 425px;
}

@media screen and (min-width: 650px) {
  .popup-options {
    max-height: inherit;
  }
}

/* Range Slider */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='range']::-webkit-slider-thumb {
    width: 15px;
    -webkit-appearance: none;
    appearance: none;
    height: 15px;
    cursor: ew-resize;
    background: white;
    box-shadow: -405px 0 0 400px black;
    border-radius: 50%;
  }

  input[type='range']::-webkit-slider-thumb::before {
    width: 15px;
    background: black;
    height: 20px;
  }
}
