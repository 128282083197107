@tailwind base;
@tailwind components;
@tailwind utilities;

#transakOnOffRampWidget {
  min-height: 681px !important;
}

.transak_modal {
  height: 681px !important;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/poppins-v15-latin-300.eot');
  src: local(''),
    url('./fonts/poppins-v15-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/poppins-v15-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/poppins-v15-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('./fonts/poppins-v15-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/poppins-v15-latin-300.svg#Poppins')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/poppins-v15-latin-regular.eot');
  src: local(''),
    url('./fonts/poppins-v15-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/poppins-v15-latin-regular.woff2') format('woff2'),
    url('./fonts/poppins-v15-latin-regular.woff') format('woff'),
    url('./fonts/poppins-v15-latin-regular.ttf') format('truetype'),
    url('./fonts/poppins-v15-latin-regular.svg#Poppins') format('svg');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/poppins-v15-latin-500.eot');
  src: local(''),
    url('./fonts/poppins-v15-latin-500.eot?#iefix') format('embedded-opentype'),
    url('./fonts/poppins-v15-latin-500.woff2') format('woff2'),
    url('./fonts/poppins-v15-latin-500.woff') format('woff'),
    url('./fonts/poppins-v15-latin-500.ttf') format('truetype'),
    url('./fonts/poppins-v15-latin-500.svg#Poppins') format('svg');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/poppins-v15-latin-600.eot');
  src: local(''),
    url('./fonts/poppins-v15-latin-600.eot?#iefix') format('embedded-opentype'),
    url('./fonts/poppins-v15-latin-600.woff2') format('woff2'),
    url('./fonts/poppins-v15-latin-600.woff') format('woff'),
    url('./fonts/poppins-v15-latin-600.ttf') format('truetype'),
    url('./fonts/poppins-v15-latin-600.svg#Poppins') format('svg');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/poppins-v15-latin-700.eot');
  src: local(''),
    url('./fonts/poppins-v15-latin-700.eot?#iefix') format('embedded-opentype'),
    url('./fonts/poppins-v15-latin-700.woff2') format('woff2'),
    url('./fonts/poppins-v15-latin-700.woff') format('woff'),
    url('./fonts/poppins-v15-latin-700.ttf') format('truetype'),
    url('./fonts/poppins-v15-latin-700.svg#Poppins') format('svg');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/poppins-v15-latin-800.eot');
  src: local(''),
    url('./fonts/poppins-v15-latin-800.eot?#iefix') format('embedded-opentype'),
    url('./fonts/poppins-v15-latin-800.woff2') format('woff2'),
    url('./fonts/poppins-v15-latin-800.woff') format('woff'),
    url('./fonts/poppins-v15-latin-800.ttf') format('truetype'),
    url('./fonts/poppins-v15-latin-800.svg#Poppins') format('svg');
}

body {
  margin: 0;
  font-family: Poppins, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply dark:bg-dark-900 transition-[background-color] duration-200;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Fix for Safari round corners - add where overflow:hidden */
.safari-mask-fix {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

/* Markdown Container */
.r-markdown-container h1,
.r-markdown h1 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.1em;
}

.r-markdown-container h2,
.r-markdown h2 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.1em;
  margin-top: 0.1em;
}

.r-markdown-container h3,
.r-markdown h3 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0.1em;
  margin-top: 0.1em;
}

.r-markdown-container ul,
.r-markdown-container ol {
  list-style-position: inside;
  padding-left: 0;
}

.r-markdown-container pre {
  text-align: left;
}

.r-markdown a {
  font-weight: bold;
  border-bottom: solid 1px #d3d8dc;
}

.r-markdown-simple h1,
.r-markdown-simple h2,
.r-markdown-simple h3 {
  @apply text-sm font-normal;
}

/* Editor.js container */
#editorjs h1 {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

#editorjs h2 {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}

#editorjs h3 {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

/* Adding styles for h4, h5 and h6 for backwards compatibility */
#editorjs h4 {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

#editorjs h5 {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

#editorjs h6 {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

#editorjs .paragraph {
  font-size: 1.1rem !important;
}

#editorjs h1,
#editorjs h2,
#editorjs h3,
#editorjs h4,
#editorjs h5,
#editorjs h6,
#editorjs p,
#editorjs li,
#editorjs .ce-paragraph,
#editorjs .ce-toolbar__plus,
#editorjs .ce-toolbar__settings-btn,
#editorjs .image-tool__caption,
#editorjs .cdx-list,
#editorjs .tc-cell,
#editorjs .cdx-quote,
#editorjs .ce-delimiter {
  @apply dark:text-white;
}

#editorjs .ce-toolbar__plus,
#editorjs .ce-toolbar__settings-btn {
  @apply dark:text-white dark:bg-dark-700 dark:border-dark-700;
}

#editorjs .tc-cell,
#editorjs .tc-add-column,
#editorjs .tc-table,
#editorjs .tc-row:after,
#editorjs .tc-row {
  @apply dark:border-dark-600;
}

#editorjs .tc-add-row,
#editorjs .tc-add-column {
  @apply dark:hover:bg-dark-600;
}

#editorjs .tc-add-row:before {
  @apply dark:bg-dark-900;
}

#editorjs .tc-cell--selected {
  @apply dark:bg-dark-700;
}

#editorjs .cdx-button {
  @apply dark:bg-dark-700 dark:text-white mt-2;
}

#editorjs .ce-toolbar__settings-btn:hover,
#editorjs .ce-toolbar__plus:hover {
  @apply dark:hover:text-dark-300;
}

#contenteditable-title:empty:before {
  content: attr(placeholder);
  display: block;
  color: #999;
}

.holo-gradient {
  background: linear-gradient(
    267.6deg,
    #f5eddf 0%,
    #f5efe1 9.91%,
    #ddf3f5 21.99%,
    #d9e8fd 33.12%,
    #dcd4fd 47%,
    #fdd7de 58.44%,
    #eef0f9 78.62%,
    #eaedf9 100%
  );
}

.text-holo {
  background: linear-gradient(
    230deg,
    #f5eddf 0%,
    #f5efe1 9.91%,
    #ddf3f5 21.99%,
    #d9e8fd 33.12%,
    #dcd4fd 47%,
    #fdd7de 58.44%,
    #eef0f9 78.62%,
    #eaedf9 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.prose {
  @apply dark:text-white;
}

/* Text selector on dark mode */
* {
  @apply dark:selection:bg-primary-500 dark:selection:text-black;
}

/* Reset for input number styles */
@layer base {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0px;
  background-color: tranparent;
}

.custom-scrollbar:hover::-webkit-scrollbar {
  width: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  -webkit-border-radius: 4px;
}

.hidden-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.hidden-scroll::-webkit-scrollbar {
  width: 100%;
  background-color: tranparent;
}

.hidden-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
}

.book-container {
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 800px;
}

@keyframes initAnimation {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(-20deg);
  }
}

/* Book Cover effect */
.book {
  aspect-ratio: 4 / 6;
  width: 90%;
  max-width: 360px;
  height: auto;
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(-20deg);
  transition: 1s ease;
  animation: 1s ease 0s 1 initAnimation;
}

.book:hover {
  transform: rotateY(0deg);
}

.book > :first-child {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  aspect-ratio: 4 / 6;
  width: 90%;
  max-width: 360px;
  height: auto;
  transform: translateZ(25px);
  border-radius: 2px 5px 5px 2px;
}

.book-light > :first-child {
  box-shadow: 5px 5px 20px #666;
  background-color: #01060f;
}

.book-dark > :first-child {
  box-shadow: 5px 5px 20px #111111;
  background-color: #2e2e2e;
}

.book::before {
  position: absolute;
  content: ' ';
  background-color: blue;
  left: 79.7%;
  top: 7px;
  width: 20%;
  max-width: 521px;
  border-radius: 4px;
  height: 88%;
  transform: translateX(0) translateZ(-12px) rotateY(80deg);
}

.book-light::before {
  background: linear-gradient(
    90deg,
    #fff 0%,
    #d7d7d7 5%,
    #fff 10%,
    #d7d7d7 15%,
    #fff 20%,
    #d7d7d7 25%,
    #fff 30%,
    #d7d7d7 35%,
    #fff 40%,
    #d7d7d7 45%,
    #fff 50%,
    #d7d7d7 55%,
    #fff 60%,
    #d7d7d7 65%,
    #fff 70%,
    #d7d7d7 75%,
    #fff 80%,
    #d7d7d7 85%,
    #fff 90%,
    #d7d7d7 95%,
    #fff 100%
  );
}

.book-dark::before {
  background: linear-gradient(
    90deg,
    #111111 0%,
    #2e2e2e 5%,
    #111111 10%,
    #2e2e2e 15%,
    #111111 20%,
    #2e2e2e 25%,
    #111111 30%,
    #2e2e2e 35%,
    #111111 40%,
    #2e2e2e 45%,
    #111111 50%,
    #2e2e2e 55%,
    #111111 60%,
    #2e2e2e 65%,
    #111111 70%,
    #2e2e2e 75%,
    #111111 80%,
    #2e2e2e 85%,
    #111111 90%,
    #2e2e2e 95%,
    #111111 100%
  );
}

.book::after {
  position: absolute;
  top: 0;
  left: 0;
  content: ' ';
  aspect-ratio: 4 / 6;
  width: 92%;
  height: auto;
  transform: translateZ(-32px);
  background-color: #01060f;
  border-radius: 0 5px 5px 0;
  box-shadow: 3.6px 6.8px 2.2px rgba(0, 0, 0, 0.005),
    6.9px 12.9px 5.3px rgba(0, 0, 0, 0.009),
    9.8px 18.4px 10px rgba(0, 0, 0, 0.015),
    12.7px 23.9px 17.9px rgba(0, 0, 0, 0.023),
    16.8px 31.6px 33.4px rgba(0, 0, 0, 0.036),
    33px 62px 80px rgba(0, 0, 0, 0.07);
}

/* Swiper Slider navigation */
.swiper-button-prev,
.swiper-button-next {
  background: black;
  border-radius: 60px;
  width: 50px !important;
  height: 50px !important;
  transition: all;
  transition-duration: 300ms;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  transform: scale(1.1);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px !important;
  color: #fff;
}

/* Mailchimp form */
#mailchimpform input {
  @apply border-gray-300 rounded-xl w-full py-[13px] focus:ring-primary-500 focus:border-primary-500 pr-[118px];
}

#mailchimpform button {
  @apply absolute top-1 right-1 bg-primary-500 font-bold py-3 px-4 text-sm rounded-lg;
}

#mailchimpform .msg-alert p {
  @apply !text-black text-xl font-bold mt-2;
}

#mailchimpform .msg-alert p {
  @apply !text-black text-xl font-bold mt-6;
}

#mailchimpform.flower-girls input {
  @apply border-gray-300 focus:ring-primary-500 focus:border-primary-500;
}

#mailchimpform.flower-girls button {
  @apply bg-black text-white;
}

/* Gradient Background */
.bg-gradient-green {
  background-color: #99ffa7;
  background-image: radial-gradient(
      at 96% 17%,
      hsla(210, 68%, 74%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 98% 56%, hsla(283, 69%, 65%, 1) 0px, transparent 50%),
    radial-gradient(at 42% 1%, hsla(40, 76%, 64%, 1) 0px, transparent 50%),
    radial-gradient(at 92% 38%, hsla(272, 78%, 78%, 1) 0px, transparent 50%),
    radial-gradient(at 49% 53%, hsla(89, 63%, 70%, 1) 0px, transparent 50%),
    radial-gradient(at 78% 81%, hsla(61, 64%, 72%, 1) 0px, transparent 50%),
    radial-gradient(at 11% 37%, hsla(154, 83%, 61%, 1) 0px, transparent 50%);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Perspective */
.perspective-parent {
  perspective: 1000px;
  perspective-origin: 50% 50%;
}

.perspective-child {
  transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(-12deg) rotateY(0deg)
    rotateZ(0deg) translateX(0px) translateY(0px) translateZ(10px) skewX(0deg)
    skewY(0deg);
  transition: all 0.5s;
}

.perspective-child:hover {
  transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg)
    rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg)
    skewY(0deg);
}

/* Perspective */
.perspective-parent-side {
  perspective: 1000px;
  perspective-origin: 50% 50%;
}

.perspective-child-side {
  transform: scaleX(0.9) scaleY(0.9) scaleZ(0.9) rotateX(20deg) rotateY(-24deg)
    rotateZ(20deg) translateX(0px) translateY(0px) translateZ(10px) skewX(0deg)
    skewY(0deg);
  transition: all 0.5s 0.5s;
}

.perspective-child-side:hover {
  transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg)
    rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg)
    skewY(0deg);
  transition-delay: 0s;
}

.perspective-parent-side-left {
  perspective: 1000px;
  perspective-origin: 50% 50%;
}

.perspective-child-side-left {
  transform: scaleX(0.9) scaleY(0.9) scaleZ(0.9) rotateX(20deg) rotateY(-24deg)
    rotateZ(20deg) translateX(0px) translateY(0px) translateZ(10px) skewX(0deg)
    skewY(0deg);
  transition: all 0.5s 0.5s;
}

.perspective-child-side-left:hover {
  transform: scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg)
    rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg)
    skewY(0deg);
  transition-delay: 0s;
}

/* Infinite zoom background */
.bg-infinite-zoom {
  background-color: #0c090a;
  background-image: url('/public/images/landing/home-hero.jpg');
  animation: zoom 30s infinite;
  -webkit-animation: zoom 30s infinite;
  background-size: 200%;
  background-position: center center;
  background-repeat: no-repeat;
}

@media screen(lg) {
  .bg-infinite-zoom {
    animation: zoom-lg 30s infinite;
    -webkit-animation: zoom-lg 30s infinite;
    background-size: 120%;
  }
}

@keyframes zoom {
  0% {
    background-size: 300%;
  }
  50% {
    background-size: 350%;
    background-position: center;
  }
  100% {
    background-size: 300%;
    background-position: center;
  }
}

@keyframes zoom-lg {
  0% {
    background-size: 125%;
  }
  50% {
    background-size: 150%;
    background-position: center;
  }
  100% {
    background-size: 125%;
    background-position: center;
  }
}

/* Dark Toggle */
.toggle,
.toggle:focus {
  --size: 1.4rem;
  appearance: none;
  outline: none;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0 #0c0c0c;
  border-radius: 999px;
  border-color: transparent;
  color: #0c0c0c;
  transition: all 500ms;
}
.toggle:checked {
  --ray-size: calc(var(--size) * -0.4);
  --offset-orthogonal: calc(var(--size) * 0.65);
  --offset-diagonal: calc(var(--size) * 0.45);
  transform: scale(0.75);
  color: #fa0;
  box-shadow: inset 0 0 0 var(--size),
    calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
    var(--offset-orthogonal) 0 0 var(--ray-size),
    0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
    0 var(--offset-orthogonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0
      var(--ray-size),
    var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
    calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
    var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
}

.toggle {
  z-index: 1;
}
.toggle:checked ~ .background {
  --bg: white;
}
